html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  width: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow-y: hidden;
}

.react-time-picker__inputGroup__input:invalid {
  background: none !important;
}

.Toastify__toast--dark {
  background-color: black !important;
}

.Toastify__toast--info {
  background-color: #277200 !important;
}
